import React from 'react'
import TextLink from '../TextLink/TextLink'

import './Footer.scss'

const Footer = () => {
  const date = new Date()

  return (
    <footer>
      <div className='footer-info'>
        <span>{`\u00a9 ${date.getFullYear()} Jason Cardarelli`}</span>
      </div>
      <span className='bullet-point'>&bull;</span>
      <TextLink to='/changelog' label='Changelog' />
    </footer>
  )
}

export default Footer
