import React from 'react'
import propTypes from 'prop-types'
import Tooltip from '../../Tooltip/Tooltip'

const NavItem = (props) => {
  const { id, theme, href, tooltip, children, action, customClass } = props

  const classNames = `btn icon-btn ${customClass}`

  return (
    <>
      {href ? (
        <a
          id={id}
          className={classNames}
          data-tooltip-content={tooltip && tooltip}
          href={href}
          target='_blank'
          rel='noopener noreferrer'>
          {children}
        </a>
      ) : (
        <button
          id={id}
          className={classNames}
          data-tooltip-content={tooltip && tooltip}
          type='button'
          onClick={action}>
          {children}
        </button>
      )}
      {tooltip && <Tooltip id={id} theme={theme} />}
    </>
  )
}

NavItem.defaultProps = {
  href: null,
  action: null,
  customClass: '',
  tooltip: '',
}

NavItem.propTypes = {
  action: propTypes.func,
  id: propTypes.string.isRequired,
  customClass: propTypes.string,
  theme: propTypes.string.isRequired,
  href: propTypes.string,
  tooltip: propTypes.string,
  children: propTypes.shape().isRequired,
}

export default NavItem
