import React from 'react'
import Page from '../../common/Page/Page'
import ChangelogMDX from '../../content/changelog/changelog.mdx'

const Changelog = () => {
  return (
    <Page title='Changelog'>
      <ChangelogMDX />
    </Page>
  )
}

export default Changelog
