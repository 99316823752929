import React from 'react'
import propTypes from 'prop-types'
import Icon from '../Icon/Icon'

import './ExternalLink.scss'

const ExternalLink = ({ label, url }) => {
  return (
    <a
      className='external-link'
      href={url}
      target='_blank'
      rel='noreferrer'
      draggable='false'>
      <span>{label}</span>&nbsp;
      <Icon name='ExternalLink' size={12} />
    </a>
  )
}

ExternalLink.propTypes = {
  label: propTypes.string.isRequired,
  url: propTypes.string.isRequired,
}

export default ExternalLink
