import React, { useContext, useState, useEffect } from 'react'
import propTypes from 'prop-types'
import UserThemeContext from '../../../context/UserTheme'
import { useStorageChild } from '../../../hooks/useStorage'
import Skeleton from 'react-loading-skeleton'

import './Image.scss'
import 'react-loading-skeleton/dist/skeleton.css'

const Image = ({ path, fileName, alt, title, className, cover }) => {
  const [theme] = useContext(UserThemeContext)

  // Firebase Storage hook
  const { image } = useStorageChild(path, fileName, theme, cover)

  const [localImage, setLocalImage] = useState(null)
  useEffect(() => {
    setLocalImage(image)
  }, [cover, path, fileName, theme, image])

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (localImage) {
      setTimeout(() => {
        setLoading(false)
      }, 300)
    }
  }, [localImage])

  return (
    <div className='image-container'>
      {loading ? (
        <Skeleton />
      ) : (
        <img
          src={localImage}
          className={
            (loading && 'loading') || (className ? className : undefined)
          }
          alt={alt}
          title={title}
        />
      )}
    </div>
  )
}

Image.defaultProps = {
  fileName: null,
  className: '',
  cover: 'default',
  alt: 'Image',
}

Image.propTypes = {
  alt: propTypes.string.isRequired,
  path: propTypes.string.isRequired,
  title: propTypes.string,
  fileName: propTypes.string,
  className: propTypes.string,
  cover: propTypes.string,
}

export default Image
