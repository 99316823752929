import { useEffect, useState } from 'react'
import { Octokit } from 'octokit'

const octokit = new Octokit({
  auth: process.env.REACT_APP_GITHUB_TOKEN,
})

export const useIssueData = (issue_number) => {
  const [issue, setIssue] = useState(null)

  useEffect(() => {
    const loadIssues = async () => {
      const getIssue = await octokit.request(
        `GET /repos/{owner}/{repo}/issues/${issue_number}`,
        {
          owner: 'jasoncardarelli',
          repo: 'portfolio',
          issue_number: 'ISSUE_NUMBER',
          headers: {
            'X-GitHub-Api-Version': '2022-11-28',
          },
        }
      )
      setIssue(getIssue)
    }

    loadIssues()
  }, [])

  return { issue }
}

export const useIssueLabels = (issue_numbers) => {
  const [labels, setLabels] = useState([])

  useEffect(() => {
    const loadIssues = async (issue_number) => {
      const getIssue = await octokit.request(
        `GET /repos/{owner}/{repo}/issues/${issue_number}`,
        {
          owner: 'jasoncardarelli',
          repo: 'portfolio',
          issue_number: 'ISSUE_NUMBER',
          headers: {
            'X-GitHub-Api-Version': '2022-11-28',
          },
        }
      )
      setLabels((item) => [
        ...item,
        { labels: getIssue.data.labels, number: getIssue.data.number },
      ])
    }
    issue_numbers.forEach((issue_number) => {
      loadIssues(issue_number)
    })
  }, [issue_numbers])

  return { labels }
}
