import React from 'react'
import Hero from './Hero/Hero'
import CardGroup from '../../common/CardGroup/CardGroup'
import TextLink from '../../common/TextLink/TextLink'
import ExternalLink from '../../common/ExternalLink/ExternalLink'
import Image from '../../common/Image/Image'

import './Home.scss'

const Home = () => {
  return (
    <div className='home'>
      <Hero />
      <CardGroup featured />
      <div className='about-brief'>
        <header>
          <h2 className='small-caps'>About</h2>
        </header>
        <div className='inner-container'>
          <Image
            path='me'
            fileName='windsor.jpeg'
            alt='Jason at Windsor Castle, in Windsor, UK.'
            title='Jason at Windsor Castle, in Windsor, UK.'
          />
          <div className='text-container'>
            <p className='callout'>
              I like crunching complex problems into good design.
            </p>
            <p className='excerpt'>
              I presently work as a Senior UX Designer for{' '}
              <ExternalLink url='https://www.icf.com' label='ICF' />, an agile
              leader in building digital health services and platforms for
              federal agencies like Centers for Medicare &amp; Medicaid Services
              (CMS).
            </p>
            <TextLink to='/about' label='About me' iconRight='ChevronRight' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
