const projects = [
  {
    name: 'Gridpad',
    url: 'gridpad',
    year: 2022,
    company: 'Personal project',
    description: 'Insert description...',
    status: 'draft',
    cover: 'default',
  },
  {
    name: 'Firefox Pinned Icons',
    url: 'firefox-pinned-icons',
    year: 2022,
    company: 'Personal project',
    description: 'Insert description...',
    status: 'draft',
    cover: 'default',
  },
  {
    name: 'Provider Data Catalog',
    url: 'provider-data-catalog',
    year: 2022,
    company: 'ICF',
    description: 'Healthcare open data platform',
    status: 'published',
    cover: 'default',
  },
  {
    name: 'Pulse Analytics Dashboard',
    url: 'pulse-analytics-dashboard',
    year: 2017,
    company: 'New York Stock Exchange',
    description: 'Compliance traning analytics web application',
    status: 'draft',
    cover: 'default',
  },
  {
    name: 'Untitled Icon Pack',
    url: 'untitled-icon-pack',
    year: 2023,
    company: 'Side Project',
    description: 'Vector-based UI icons',
    status: 'published',
    cover: 'dynamic',
  },
]

export default projects
