import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import projects from '../../../../assets/projects'
import CardGroup from '../../../common/CardGroup/CardGroup'
import Page from '../../../common/Page/Page'

import './ProjectDetails.scss'

const ProjectDetails = () => {
  // Get current projectId from react-router
  const params = useParams()
  const { projectId } = params

  // Get current project
  const [project, setProject] = useState({})
  useEffect(() => {
    setProject(projects.filter((item) => item.url === projectId)[0])
  }, [projectId])

  // Get current MDX file
  const [data, setData] = useState()
  useEffect(() => {
    import(`../../../content/projects/${projectId}.mdx`)
      .then((module) => {
        setData(module.default)
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }, [projectId])

  /* Check if the project object has its keys
     Using this conditional prevents an 'undefined' error */
  const projectKeys = Object.keys(project).length > 0

  return (
    projectKeys && (
      <Page
        sub
        title={project.name}
        subtitle={project.description}
        data={project}
        parent='projects'>
        {data}
        <CardGroup moreProjects currentProject={projectId} />
      </Page>
    )
  )
}

export default ProjectDetails
