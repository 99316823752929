import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Menu from './Menu/Menu'
import IconButtonGroup from './IconButtonGroup/IconButtonGroup'
import { useBp } from '../../../utils/Breakpoints/Breakpoints'

import './Header.scss'

const Header = () => {
  const [scrollDirection, setScrollDirection] = useState(null)
  const [showHeader, setShowHeader] = useState(true)
  const [transition, setTransition] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation().pathname

  useEffect(() => {
    setTransition(null)
    setTimeout(() => {
      setTransition('transform ease-out 0.3s')
    }, 1000)
  }, [location])

  useEffect(() => {
    let lastScrollY = window.scrollY

    const updateScrollDirection = () => {
      const { scrollY } = window
      // If current Y is > former Y, I'm scrolling down
      const direction = scrollY > lastScrollY ? 'down' : 'up'
      if (
        /* If the new scroll direction is strictly not equal to the
           former scroll direction, */
        direction !== scrollDirection &&
        /* And the difference between current Y and former Y is > 10px
           in either direction, update the direction */
        (scrollY - lastScrollY > 12 || scrollY - lastScrollY < -12)
      ) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0

      // 80px is paddingTop + header height
      if (scrollY < 80) {
        setShowHeader(true)
      } else if (scrollDirection === 'up') {
        setShowHeader(true)
      } else if (scrollDirection === 'down' && !menuOpen) {
        setShowHeader(false)
      }
    }

    // Add event listener for 'scroll'
    window.addEventListener('scroll', updateScrollDirection)

    // Clean up effect: remove event listener for 'scroll'
    return () => {
      window.removeEventListener('scroll', updateScrollDirection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollDirection])

  const transform = !showHeader ? 'translateY(-100%)' : ''

  return (
    <header
      className='site-header'
      style={{ transform: transform, transition: transition }}>
      <nav className='primary-nav'>
        <NavLink className='btn nav-btn name' draggable='false' end to='/'>
          Jason Cardarelli
        </NavLink>
        {useBp('> xs') && (
          <>
            <NavLink className='btn nav-btn' draggable='false' to='/projects'>
              Projects
            </NavLink>
            <NavLink className='btn nav-btn' draggable='false' to='/about'>
              About
            </NavLink>
          </>
        )}
      </nav>
      <nav className='secondary-nav'>
        {useBp('> xs') && <IconButtonGroup tooltip />}
        {useBp('< sm') && (
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        )}
      </nav>
    </header>
  )
}

export default Header
