import { useState, useEffect, useContext } from 'react'
import v from '../../styles/modules/_variables.scss'
import CurrentBpContext from '../../context/CurrentBp'

// Import Sass variable(s)
const {
  'breakpoints-xxs': xxs,
  'breakpoints-xs': xs,
  'breakpoints-sm': sm,
  'breakpoints-md': md,
  'breakpoints-lg': lg,
  'breakpoints-xl': xl,
} = v

// Define breakpoints array
const breakpoints = [{ xxs }, { xs }, { sm }, { md }, { lg }, { xl }]

// Test for media query match for given breakpoint
const mqlTest = (bp) => {
  const mql = window.matchMedia(`(min-width: ${bp})`)
  return mql
}

// Functions to get one value or key from object
const getKey = (bp) => Object.keys(bp)[0]
const getValue = (bp) => Object.values(bp)[0]

export const useBpListener = () => {
  // Figure out initial breakpoint on page load
  const initialBp = () => {
    let x
    breakpoints.forEach((bp) => {
      if (mqlTest(getValue(bp)).matches) {
        x = getKey(bp)
      }
    })
    return x
  }

  const [currentBp, setCurrentBp] = useState(initialBp())

  useEffect(() => {
    // Resize handler
    const handleResize = () => {
      breakpoints.forEach((bp) => {
        if (mqlTest(getValue(bp)).matches) {
          setCurrentBp(getKey(bp))
        }
      })
    }

    // Add resize listener
    window.addEventListener('resize', handleResize)

    return () => {
      // Remove resize listener
      window.removeEventListener('resize', handleResize)
    }
  })

  return currentBp
}

export const useBp = (input) => {
  const CurrentBp = useContext(CurrentBpContext)

  const operator = input.split(' ')[0]
  const breakpoint = input.split(' ')[1]

  // eslint-disable-next-line no-unused-vars
  const getBpValue = (bp) => {
    const filteredItem = breakpoints.filter((item) => item[bp])
    return Object.values(filteredItem[0])[0]
  }

  const getBpKey = (bp) => {
    // TODO: add check for if key exists: if (Object.hasOwn())
    const filteredItem = breakpoints.filter((item) => item[bp])
    return Object.keys(filteredItem[0])[0]
  }

  const bpScale = [
    { xxs: 1 },
    { xs: 2 },
    { sm: 3 },
    { md: 4 },
    { lg: 5 },
    { xl: 6 },
  ]

  const getBpScale = (bp) => {
    const filteredBp = bpScale.filter((item) => item[bp])[0]
    return Object.values(filteredBp)[0]
  }

  let value

  switch (operator) {
    case '==':
      if (CurrentBp === getBpKey(breakpoint)) {
        value = true
      }
      break
    case '>':
      if (getBpScale(CurrentBp) > getBpScale(breakpoint)) {
        value = true
      }
      break
    case '<':
      if (getBpScale(CurrentBp) < getBpScale(breakpoint)) {
        value = true
      }
      break
    default:
      // eslint-disable-next-line no-console
      console.log(`No such operator: ${operator}`)
  }

  return value
}
