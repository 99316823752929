import React, { useContext } from 'react'
import propTypes from 'prop-types'
import Icon from '../../Icon/Icon'
import { setStorage } from '../../../../utils/LocalStorage/LocalStorage'
import NavItem from '../NavItem/NavItem'
import UserThemeContext from '../../../../context/UserTheme'

const IconButtonGroup = ({ tooltip }) => {
  const [theme, setUserTheme] = useContext(UserThemeContext)

  // Capitalizes strings
  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  // Checks theme and toggles between them
  const toggleTheme = (u) => {
    if (theme === 'dark') {
      return u ? capitalize('light') : 'light'
    }
    return u ? capitalize('dark') : 'dark'
  }

  // Returns corresponding icon with bracket notation
  const themeIcon = {
    light: 'SunOutline',
    dark: 'MoonOutline',
  }
  const iconButtons = [
    {
      title: 'GitHub',
      id: 'github',
      icon: 'Github',
      alt: 'GitHub logo',
      href: 'https://github.com/jasoncardarelli',
    },
    {
      title: 'LinkedIn',
      id: 'linkedin',
      icon: 'LinkedinSquare',
      alt: 'LinkedIn logo',
      href: 'https://www.linkedin.com/in/jasoncardarelli',
    },
    {
      title: `${toggleTheme('uppercase')} mode`,
      id: 'theme',
      icon: themeIcon[toggleTheme()],
      alt: `${toggleTheme('uppercase')} mode`,
      action: () => {
        setUserTheme(toggleTheme())
        setStorage('userTheme', toggleTheme())
      },
    },
  ]

  return (
    <div className='icon-button-group'>
      {iconButtons.map((btn) => {
        return (
          <NavItem
            key={btn.id}
            id={btn.id}
            theme={theme}
            tooltip={tooltip ? btn.title : ''}
            href={btn.href}
            action={btn.action}>
            <Icon name={btn.icon} alt={btn.alt} />
          </NavItem>
        )
      })}
    </div>
  )
}

IconButtonGroup.defaultProps = {
  tooltip: false,
}

IconButtonGroup.propTypes = {
  tooltip: propTypes.bool,
}

export default IconButtonGroup
