import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import propTypes from 'prop-types'

import v from '../../../styles/modules/_variables.scss'
import 'react-tooltip/dist/react-tooltip.css'
import './Tooltip.scss'
// Import Sass variable(s)
const { timing } = v
const timingNumber = Number(timing)

const Tooltip = ({ id, theme, delayShow }) => {
  return (
    <ReactTooltip
      anchorId={id}
      className='react-tooltip'
      delayHide={timingNumber}
      delayShow={delayShow}
      noArrow
      offset={8}
      place='bottom'
      variant={theme}
    />
  )
}

Tooltip.defaultProps = {
  delayShow: timingNumber,
}

Tooltip.propTypes = {
  delayShow: propTypes.number,
  id: propTypes.string.isRequired,
  theme: propTypes.string.isRequired,
}

export default Tooltip
