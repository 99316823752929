import React from 'react'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Icon from '../Icon/Icon'

import './TextLink.scss'

const TextLink = ({ label, to, iconLeft, iconRight, className }) => {
  const iconClass = (iconLeft && 'icon-left') || (iconRight && 'icon-right')
  return (
    <Link
      to={to}
      className={`text-link ${iconClass} ${className}`}
      draggable='false'>
      {iconLeft && <Icon name={iconLeft} size={16} />}
      <span>{label}</span>
      {iconRight && <Icon name={iconRight} size={16} />}
    </Link>
  )
}

TextLink.defaultProps = {
  iconLeft: '',
  iconRight: '',
  className: '',
}

TextLink.propTypes = {
  label: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
  className: propTypes.string,
  iconLeft: propTypes.string,
  iconRight: propTypes.string,
}

export default TextLink
