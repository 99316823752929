import React from 'react'
import { Outlet } from 'react-router-dom'
import propTypes from 'prop-types'
import Card from '../Card/Card'
import projects from '../../../assets/projects'
import TextLink from '../TextLink/TextLink'

import './CardGroup.scss'

const CardGroup = ({ featured, moreProjects, currentProject }) => {
  const projectList = ['provider-data-catalog', 'untitled-icon-pack']

  const featuredProjects = projects.filter((project) =>
    projectList.includes(project.url)
  )

  const publishedProjects = projects.filter(
    (item) => item.status === 'published'
  )

  const allButCurrentProjects = publishedProjects.filter(
    (item) => item.url !== currentProject
  )

  // Sort projects by year from newest to oldest
  const projectsByNewest = featured
    ? featuredProjects.sort((a, b) => b.year - a.year)
    : publishedProjects.sort((a, b) => b.year - a.year)

  const featuredClass = featured ? 'featured' : ''
  const moreProjectsClass = moreProjects ? 'more-projects' : ''

  return (
    <div className={`card-group ${featuredClass} ${moreProjectsClass}`}>
      {featured && (
        <header className='card-group-header'>
          <h2 className='small-caps'>Featured Projects</h2>
          <TextLink
            to='/projects'
            label={`View all (${publishedProjects.length})`}
            iconRight='ChevronRight'
          />
        </header>
      )}
      {moreProjects && (
        <div className='card-group-header'>
          <h2 className='small-caps'>More Projects</h2>
        </div>
      )}
      <div className='card-group-container'>
        {!moreProjects
          ? projectsByNewest.map((project) => (
              <Card key={project.url} project={project} />
            ))
          : allButCurrentProjects.map((project) => (
              <Card key={project.url} project={project} />
            ))}
        <Outlet />
      </div>
    </div>
  )
}

CardGroup.defaultProps = {
  featured: false,
  moreProjects: false,
  currentProject: null,
}

CardGroup.propTypes = {
  featured: propTypes.bool,
  moreProjects: propTypes.bool,
  currentProject: propTypes.string,
}

export default CardGroup
