import React from 'react'
import propTypes from 'prop-types'
import TextLink from '../TextLink/TextLink'

import './PageHeader.scss'

const PageHeader = ({ title, subtitle, backPath, children }) => {
  return (
    <header className='page-header'>
      <div className='text-container'>
        {backPath && (
          <TextLink
            to={backPath}
            label='Back to Projects'
            iconLeft='ChevronLeft'
          />
        )}
        <h1>{title}</h1>
        {subtitle && <span className='subtitle'>{subtitle}</span>}
      </div>
      {children}
    </header>
  )
}

PageHeader.defaultProps = {
  subtitle: '',
  backPath: '',
  children: null,
}

PageHeader.propTypes = {
  title: propTypes.string.isRequired,
  subtitle: propTypes.oneOfType([propTypes.shape(), propTypes.string]),
  backPath: propTypes.string,
  children: propTypes.shape(),
}

export default PageHeader
