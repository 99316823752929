import React from 'react'
import CardGroup from '../../common/CardGroup/CardGroup'
import Page from '../../common/Page/Page'

import './Projects.scss'

const Projects = () => {
  return (
    <Page title='Projects'>
      <CardGroup />
    </Page>
  )
}

export default Projects
