// Checks if specified key exists (boolean)
export const checkStorage = (key) => {
  let bool = null
  if (window.localStorage.getItem(key) !== null) {
    bool = true
  } else {
    bool = false
  }
  return bool
}

// Gets value for specified key
export const getStorage = (key) => {
  return window.localStorage.getItem(key)
}

// Sets value for specified key
export const setStorage = (key, value) => {
  return window.localStorage.setItem(key, value)
}
