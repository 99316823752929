import React from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'
import Image from '../Image/Image'

import './Card.scss'

const Card = ({ project }) => {
  return (
    <Link
      className={`card ${project.url}`}
      to={`/projects/${project.url}`}
      key={project.url}>
      <div className='card-text'>
        <span className='card-name'>{project.name}</span>
        <span className='card-description'>{project.description}</span>
      </div>
      <Image
        path={`projects/${project.url}`}
        className='cover'
        alt={`${project.name} cover image`}
        cover={project.cover}
      />
    </Link>
  )
}

Card.propTypes = {
  project: propTypes.shape({
    cover: propTypes.string,
    description: propTypes.string,
    name: propTypes.string,
    url: propTypes.string,
  }).isRequired,
}

export default Card
