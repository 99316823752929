import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import ExternalLink from '../../../common/ExternalLink/ExternalLink'
import Icon from '../../../common/Icon/Icon'
import { useIssueData } from '../../../../hooks/useGitHubAPI'
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import './Branch.scss'

const Branch = ({ children, issueNumber, lastChild }) => {
  const { issue } = useIssueData(issueNumber)

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (issue !== null) {
      setLoading(false)
    }
  }, [issue])

  const lastChildClass = lastChild ? 'last-child' : ''
  const loadingClass = loading ? 'loading' : ''
  const showIssueLink = false

  return (
    <section className={`branch ${lastChildClass} ${loadingClass}`}>
      <div className='connector-container'>
        <Icon name='CircleOutline' size={16} />
        <div className='connector' />
        {lastChild && (
          <Icon className='small-circle' name='BulletCircleMedium' size={16} />
        )}
      </div>
      <div className='branch-content'>
        <header>
          <div className='header-text-container'>
            <div className='header-group'>
              <h3>
                {loading ? (
                  <Skeleton containerClassName='sk-title' />
                ) : (
                  issue.data.title
                )}
              </h3>
            </div>
            <div className='issue-details'>
              {showIssueLink ? (
                loading ? (
                  <Skeleton containerClassName='sk-issue-number' />
                ) : (
                  <ExternalLink
                    label={`#${issueNumber}`}
                    url={issue.data.html_url}
                  />
                )
              ) : (
                <span className='issue-number'>{`#${issueNumber}`}</span>
              )}
              {loading ? (
                <Skeleton containerClassName='sk-labels' />
              ) : (
                issue.data.labels.map((label) => {
                  return (
                    <span key={label.name} className='label'>
                      {label.name}
                    </span>
                  )
                })
              )}
            </div>
          </div>
        </header>
        <div className='list'>{children}</div>
      </div>
    </section>
  )
}

Branch.defaultProps = {
  children: <span>No children</span>,
  lastChild: false,
}

Branch.propTypes = {
  children: propTypes.oneOfType([
    propTypes.array,
    propTypes.shape(),
    propTypes.string,
  ]),
  issueNumber: propTypes.number.isRequired,
  lastChild: propTypes.bool,
}

export default Branch
