import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './components/routes/App/App'
import Colors from './components/routes/Colors/Colors'
import Changelog from './components/routes/Changelog/Changelog.js'
import Projects from './components/routes/Projects/Projects'
import Home from './components/routes/Home/Home'
import ProjectDetails from './components/routes/Projects/ProjectDetails/ProjectDetails'
import reportWebVitals from './services/reportWebVitals'
import ScrollToTop from './utils/ScrollToTop/ScrollToTop'
import About from './components/routes/About/About'

import './index.scss'

const container = document.getElementById('app')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path='/' element={<App />}>
        <Route index element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='projects'>
          <Route index element={<Projects />} />
          <Route path=':projectId' element={<ProjectDetails />} />
        </Route>
        <Route path='colors' element={<Colors />} />
        <Route path='changelog' element={<Changelog />} />
        <Route path='*' element={<p>There is nothing here!</p>} />
      </Route>
    </Routes>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
