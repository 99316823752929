import React from 'react'
import Page from '../../common/Page/Page'
import AboutMDX from '../../content/about/about.mdx'

import './About.scss'

const About = () => {
  return (
    <Page title='About'>
      <AboutMDX />
    </Page>
  )
}

export default About
