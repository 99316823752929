import React from 'react'
import propTypes from 'prop-types'

import './Section.scss'

// eslint-disable-next-line no-unused-vars
const Section = ({ children, title, toggle }) => {
  return (
    <section className='section'>
      <header>
        <h2 className='small-caps'>{title}</h2>
      </header>
      <div>{children}</div>
    </section>
  )
}

Section.defaultProps = {
  children: <span>No children</span>,
  title: 'Default',
  toggle: false,
}

Section.propTypes = {
  children: propTypes.oneOfType([
    propTypes.array,
    propTypes.shape(),
    propTypes.string,
  ]),
  title: propTypes.string,
  toggle: propTypes.bool,
}

export default Section
