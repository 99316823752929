import React from 'react'
import propTypes from 'prop-types'
import PageHeader from '../PageHeader/PageHeader'
import { Helmet } from 'react-helmet'
import Image from '../Image/Image'

const Page = ({ title, children, sub, parent, data }) => {
  const titlePrefix = 'Jason Cardarelli • '

  // Transforms strings from "Foo Bar" to "foo-bar"
  const toSlug = (str) => {
    let slug = str.toLowerCase()
    if (str.includes(' ')) {
      slug = slug.replaceAll(' ', '-')
    }
    return slug
  }

  // Transforms strings from "foo bar" to "Foo bar"
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleClassNames = () => {
    let classNames
    sub
      ? (classNames = `page sub ${parent} ${toSlug(title)}`)
      : (classNames = `page ${toSlug(title)}`)
    return classNames
  }

  return (
    <div className={handleClassNames()}>
      <Helmet>
        <title>{`${titlePrefix}${
          sub ? capitalize(parent) + '/' + title : title
        }`}</title>
      </Helmet>

      <PageHeader
        title={title}
        subtitle={sub && data.description}
        backPath={sub && `/${parent}`}>
        {sub && (
          <Image
            path={`${parent}/${toSlug(title)}`}
            className='cover'
            alt={`${data.name} cover image`}
            cover={data.cover}
          />
        )}
      </PageHeader>
      {children}
    </div>
  )
}

Page.defaultProps = {
  children: null,
  title: 'Title',
  subtitle: '',
}

Page.propTypes = {
  title: propTypes.string.isRequired,
  sub: propTypes.bool,
  data: propTypes.shape(),
  parent: propTypes.string,
  subtitle: propTypes.oneOfType([propTypes.shape(), propTypes.string]),
  children: propTypes.oneOfType([propTypes.shape(), propTypes.array]),
}

export default Page
