import { useEffect, useState } from 'react'
import { ref, listAll, getDownloadURL } from 'firebase/storage'
import { storage } from '../services/firebase'

// Gets children inside folder
export const useStorageAllChildren = (parentFolder, childFolder) => {
  const [allChildren, setAllChildren] = useState([])
  const listRef = ref(storage, `${parentFolder}/${childFolder}`)

  useEffect(() => {
    let isSubscribed = true
    listAll(listRef)
      .then((res) => {
        if (isSubscribed) {
          res.items.forEach((itemRef) => {
            getDownloadURL(itemRef).then((urls) => {
              setAllChildren((arr) => [...arr, urls])
            })
          })
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })

    return () => {
      isSubscribed = false
      setAllChildren([])
    }
  }, [childFolder, listRef])

  return { allChildren }
}

// Gets folders and first child from each
export const useStorageFirstChildren = (parentFolder) => {
  const [firstChildren, setFirstChildren] = useState([])
  const [folder, setFolder] = useState([])
  const folderRef = ref(storage, parentFolder)

  useEffect(() => {
    let isSubscribedFolder = true
    listAll(folderRef)
      .then((res) => {
        if (isSubscribedFolder) {
          res.prefixes.forEach((eachFolder) => {
            if (!folder.includes(eachFolder.name)) {
              setFolder((arr) => [...arr, eachFolder.name])
            }
          })
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })

    return () => {
      isSubscribedFolder = false
    }
  }, [folder, folderRef])

  useEffect(() => {
    let isSubscribedItem = true
    folder.forEach((item) => {
      const itemRef = ref(folderRef, item)

      listAll(itemRef)
        .then((res) => {
          if (isSubscribedItem) {
            getDownloadURL(res.items[0]).then((newUrl) => {
              if (!firstChildren.some((key) => key[item])) {
                setFirstChildren((arr) => [...arr, { [item]: newUrl }])
              }
            })
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    })

    return () => {
      isSubscribedItem = false
    }
  }, [folder, firstChildren, folderRef])

  return { firstChildren }
}

// Gets first child from specified project folder
export const useStorageFirstChild = (parentFolder, childFolder) => {
  const [firstChild, setFirstChild] = useState(null)
  const folderRef = ref(storage, parentFolder)

  useEffect(() => {
    let isSubscribedItem = true
    const itemRef = ref(folderRef, childFolder)

    listAll(itemRef)
      .then((res) => {
        if (isSubscribedItem) {
          getDownloadURL(res.items[0]).then((newUrl) => {
            setFirstChild(newUrl)
          })
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })

    return () => {
      isSubscribedItem = false
    }
  }, [childFolder, folderRef])

  return { firstChild }
}

/* Filname
   Gets specified image from specified folder
   * `path` must include full path except file name
   * `fileName` must include extension
/* Cover, Default
   Gets default cover image from specified folder
   * `path` must include full path except 'cover' directory and file name
   * Image must be within 'cover` directory
   * Image must be named 'cover.png'
/* Cover, Dynamic
   Gets dynamic, theme-based cover images from specified folder
   * `path` must include full path except 'cover' directory and file name
   * `theme` must be either 'light' or 'dark'
   * Images must be within 'cover` directory
   * There must be two images named 'cover-light.png' and 'cover-dark.png' */

export const useStorageChild = (path, fileName, theme, cover) => {
  const [image, setImage] = useState(null)
  const [itemRef, setItemRef] = useState(null)

  useEffect(() => {
    if (fileName) {
      // Filename
      setItemRef(ref(storage, `${path}/${fileName}`))
    } else if (cover === 'default') {
      // Cover
      setItemRef(ref(storage, `${path}/cover/cover.png`))
    } else if (cover === 'dynamic') {
      // Dynamic Cover
      setItemRef(ref(storage, `${path}/cover/cover-${theme}.png`))
    }
  }, [path, fileName, theme, cover])

  useEffect(() => {
    if (itemRef !== null) {
      getDownloadURL(itemRef)
        .then((newUrl) => {
          setImage(newUrl)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    }
  }, [itemRef])

  return { image }
}
