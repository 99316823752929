import React, { useEffect, useRef, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import NavItem from '../NavItem/NavItem'
import IconButtonGroup from '../IconButtonGroup/IconButtonGroup'
import Icon from '../../Icon/Icon'
import UserThemeContext from '../../../../context/UserTheme'
import propTypes from 'prop-types'

const Menu = ({ menuOpen, setMenuOpen }) => {
  const [theme] = useContext(UserThemeContext)

  const useOutsideClick = (callback) => {
    const ref = useRef()
    useEffect(() => {
      const handleClick = (e) => {
        if (
          ref.current &&
          !ref.current.contains(e.target) &&
          !e.target.matches('.btn')
        ) {
          // Closes the menu
          callback()
        }
      }

      // Set event listener
      document.addEventListener('mousedown', handleClick)

      // Clean up effect
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }
    }, [ref, callback])

    return ref
  }

  const handleClickOutside = () => {
    setMenuOpen(false)
  }

  const menuRef = useOutsideClick(handleClickOutside)

  return !menuOpen ? (
    <NavItem
      id='menu'
      customClass='menu-btn'
      theme={theme}
      action={() => {
        setMenuOpen(true)
      }}>
      <Icon name='Menu' alt='Open menu' />
    </NavItem>
  ) : (
    <>
      <NavItem
        id='close'
        customClass='menu-btn'
        theme={theme}
        action={() => {
          setMenuOpen(false)
        }}>
        <Icon name='Close' alt='Close menu' />
      </NavItem>
      <nav className='menu-nav' ref={menuRef}>
        <NavLink
          className='btn nav-btn'
          to='/projects'
          draggable='false'
          onClick={() => {
            setMenuOpen(false)
          }}>
          Projects
        </NavLink>
        <NavLink
          className='btn nav-btn'
          to='/about'
          draggable='false'
          onClick={() => {
            setMenuOpen(false)
          }}>
          About
        </NavLink>
        <IconButtonGroup />
      </nav>
    </>
  )
}

Menu.propTypes = {
  menuOpen: propTypes.bool.isRequired,
  setMenuOpen: propTypes.func.isRequired,
}

export default Menu
