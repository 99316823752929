import React from 'react'
import Color from 'colorjs.io'
import { useOutletContext } from 'react-router-dom'
import Section from '../../common/Section/Section'
import ExternalLink from '../../common/ExternalLink/ExternalLink'
import Page from '../../common/Page/Page'

import v from '../../../styles/modules/_variables.scss'

import './Colors.scss'

const Colors = () => {
  // Import Sass variable(s)
  const { increment, stepCount } = v

  const theme = useOutletContext()
  const colors = [
    {
      name: 'gray',
      start: new Color('lch', [96, 2, 248]),
      end: new Color('lch', [10, 8, 256]),
    },
    {
      name: 'blue',
      start: new Color('lch', [96, 11, 268]),
      end: new Color('lch', [32, 92, 296]),
    },
    {
      name: 'red',
      start: new Color('lch', [96, 4, 48]),
      end: new Color('lch', [32, 64, 32]),
    },
  ]

  const generateSteps = (color) => {
    const steps = color.start.steps(color.end, {
      space: 'lch',
      outputSpace: 'srgb',
      maxDeltaE: 0, // max deltaE between consecutive steps (optional)
      steps: stepCount, // min number of steps
    })

    const stringSteps = steps.map((item, i) => {
      const x = (i + 1) * increment
      return {
        name: color.name,
        number: x,
        id: `${color.name}-${x}`,
        value: item.toString({ format: 'hex' }),
      }
    })

    return stringSteps
  }

  const colorSwatches = colors.map((color) => {
    return generateSteps(color)
  })

  const Subtitle = () => {
    return (
      <>
        This is the color palette used for this site. Each set is using the LCH
        color space and is generated using{' '}
        <ExternalLink url='https://colorjs.io/' label='Color.js' />.
      </>
    )
  }

  // Double-click to copy to clipboard
  const handleClick = (e) => {
    const { detail, currentTarget } = e
    if (detail === 2) {
      const text = currentTarget.textContent
      navigator.clipboard.writeText(text)
    }
  }

  return (
    <Page title='Colors' subtitle={<Subtitle />}>
      {/* Map through color list */}
      {colorSwatches.map((item) => {
        return (
          <Section title={item[0].name} key={item.name}>
            {/* SASS */}
            <div className='color-container sass' onClick={handleClick}>
              <span>{`$${item[0].name}: (`}</span>
              {/* Map through each color */}
              {item.map((color, i) => {
                // Invert colors based on theme and index
                const invert = () => {
                  let invertValue = ''
                  if (theme === 'dark' && i <= stepCount / 2) {
                    invertValue = 'invert-dark'
                  } else if (theme === 'light' && i >= stepCount / 2) {
                    invertValue = 'invert-light'
                  }
                  return invertValue
                }
                return (
                  <div key={color.id} className={`color-row ${invert()}`}>
                    <span className='whitespace'>{'  '}</span>
                    <span
                      className='color-name'
                      style={{
                        backgroundColor: color.value,
                      }}>
                      {`'${color.number}':`}
                    </span>
                    <span className='whitespace'> </span>
                    <span className='color-value'>{`${color.value},`}</span>
                  </div>
                )
              })}
              <span>);</span>
            </div>
          </Section>
        )
      })}
    </Page>
  )
}

export default Colors
