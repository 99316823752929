import React, { cloneElement, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import Icon from '../../../common/Icon/Icon'
import { useIssueLabels } from '../../../../hooks/useGitHubAPI'
import Skeleton from 'react-loading-skeleton'

import './Deploy.scss'

const Deploy = ({ children, date }) => {
  const [menuOpen, setMenuOpen] = useState(true)
  const [issueArray, setIssueArray] = useState([])

  // Clone children to give them menuOpen prop
  const clonedChildren = () => {
    let newClone
    const lastChild = (c) => children.indexOf(c) === children.length - 1
    if (children.length > 1) {
      newClone = children.map((child) => {
        return cloneElement(child, {
          key: child.props.issueNumber,
          menuOpen,
          lastChild: lastChild(child),
        })
      })
    } else {
      newClone = cloneElement(children, { menuOpen, lastChild: true })
    }
    return newClone
  }

  useEffect(() => {
    if (children.length > 1) {
      children.forEach((child) =>
        setIssueArray((item) => [...item, child.props.issueNumber])
      )
    } else {
      setIssueArray([children.props.issueNumber])
    }
  }, [children])

  const { labels } = useIssueLabels(issueArray)

  const handleMenuClick = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true)
  }

  const [labelCounts, setLabelCounts] = useState([])
  useEffect(() => {
    // Get label names from array
    const labelNames = labels.map((item) =>
      item.labels.map((label) => label.name)
    )

    // Flatten nested arrays of label names to one level
    const flatLabels = labelNames.flat()

    // Get a list of unique label names (one of each existing)
    const getUnique = [...new Set(flatLabels)]

    // Create an array of objects containing the counts
    setLabelCounts(
      getUnique.map((label) => {
        return { [label]: flatLabels.filter((item) => item === label).length }
      })
    )
  }, [labels])

  const loadingClass = !labelCounts.length ? 'loading' : ''

  const dateObj = {
    // JavaScript thought this was 1923
    year: '20' + date.slice(0, 2),
    // Month is 0-indexed
    month: date.slice(2, 4) - 1,
    day: date.slice(4, 6),
    revision: date.slice(6, 8),
  }

  const { year, month, day } = dateObj
  const humanDate = new Date(year, month, day)
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const getMonthName = (i) => {
    return monthNames[i]
  }

  const dateString = `${getMonthName(
    humanDate.getMonth()
  )}. ${humanDate.getDate()}, ${humanDate.getFullYear()}`

  return (
    <div className={`deploy ${loadingClass}`}>
      <header>
        <button onClick={() => handleMenuClick()}>
          <Icon name={menuOpen ? 'ChevronDown' : 'ChevronRight'} size={20} />
        </button>
        <div className='header-text-container'>
          <div className='date-container'>
            <h2>{dateString}</h2>
            <span>Deploy #{date}</span>
          </div>
          <div className='label-counts'>
            {!labelCounts.length ? (
              <Skeleton containerClassName='sk-count' />
            ) : (
              labelCounts.map((item, i) => {
                const count = Object.values(item)[0]

                let name = Object.keys(item)[0]

                switch (name) {
                  case 'bug':
                    count === 1
                      ? (name = name + ' fix')
                      : count > 1
                      ? (name = name + ' fixes')
                      : name
                    break
                  case 'maintenance':
                    count === 1
                      ? (name = name + ' fix')
                      : count > 1
                      ? (name = name + ' fixes')
                      : name
                    break
                  default:
                    count > 1 ? (name = name + 's') : name
                    break
                }
                return (
                  <>
                    <div className={`${name}-count`} key={name}>
                      <span>{`${count} ${name}`}</span>
                    </div>
                    {i + 1 !== labelCounts.length && (
                      <Icon
                        className='small-circle'
                        name='BulletCircleMedium'
                        size={16}
                      />
                    )}
                  </>
                )
              })
            )}
          </div>
        </div>
      </header>
      {menuOpen && <div className='branch-container'>{clonedChildren()}</div>}
    </div>
  )
}

Deploy.defaultProps = {
  children: <span>No children</span>,
}

Deploy.propTypes = {
  children: propTypes.oneOfType([
    propTypes.array,
    propTypes.shape(),
    propTypes.string,
  ]),
  date: propTypes.string.isRequired,
}

export default Deploy
