import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const Icon = (props) => {
  const { height, width, name, size, alt, className } = props

  const [IconComponent, setIconComponent] = useState(null)
  useEffect(() => {
    const loadIcon = async () => {
      try {
        const ico = await import(
          `!!@svgr/webpack?-svgo,+titleProp,+ref!./../../../assets/icons/${name}.svg`
        )
        setIconComponent(() => ico.default)
      } catch (err) {
        console.error(err)
      }
      return IconComponent
    }
    loadIcon()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  const hyphenate = (string) => {
    return string.replace(
      /[A-Z]/g,
      (match, offset) => (offset > 0 ? '-' : '') + match.toLowerCase()
    )
  }

  const iconAttr = {
    className: className ? `${className} ${hyphenate(name)}` : hyphenate(name),
    height: size || height,
    width: size || width,
    role: 'img',
    alt: alt || name,
  }

  return IconComponent ? <IconComponent {...iconAttr} /> : null
}

Icon.defaultProps = {
  name: 'sun',
  alt: '',
  width: 24,
  height: 24,
  size: 24,
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  size: PropTypes.number,
  alt: PropTypes.string,
}

export default Icon
