import React from 'react'
import TextLink from '../../../common/TextLink/TextLink'

import './Hero.scss'

const Hero = () => {
  const Tags = () => {
    const tags = [
      '10+ years experience',
      'UX design',
      'React',
      'Design systems',
    ]
    return (
      <div className='tags'>
        {tags.map((tag) => {
          return (
            <span key={tag} className='tag child'>
              {tag}
            </span>
          )
        })}
        <TextLink
          to='/about'
          label='More'
          iconRight='ChevronRight'
          className='child'
        />
      </div>
    )
  }

  return (
    <div className='hero'>
      <div className='hero-text-container'>
        <h1 className='display'>
          I design interfaces and systems with a focus on accessibility.
        </h1>
        <div className='tag-container'>
          <Tags />
        </div>
      </div>
    </div>
  )
}

export default Hero
