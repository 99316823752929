import React, { useState, useEffect, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Helmet } from 'react-helmet'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import UserThemeContext from '../../../context/UserTheme'
import CurrentBpContext from '../../../context/CurrentBp'
import { useBpListener } from '../../../utils/Breakpoints/Breakpoints'
import {
  checkStorage,
  getStorage,
  setStorage,
} from '../../../utils/LocalStorage/LocalStorage'
import v from '../../../styles/modules/_color-map.scss'

const App = () => {
  // Import colors from Sass
  const { gray1100, gray1150, gray100, gray150 } = v

  const currentBp = useBpListener()

  // Light - Returns 'light' if system theme is 'light'
  const lightMqList = window.matchMedia('(prefers-color-scheme: light)')
  const lightMatch = lightMqList.matches
  const lightTest = () => lightMatch && 'light'

  // Dark - Returns 'dark' if system theme is 'dark'
  const darkMqList = window.matchMedia('(prefers-color-scheme: dark)')
  const darkMatch = darkMqList.matches
  const darkTest = () => darkMatch && 'dark'

  // Set initial theme based on system theme
  const [defaultTheme, setDefaultTheme] = useState(darkTest() || lightTest())
  const [userTheme, setUserTheme] = useState(null)
  const theme = userTheme || defaultTheme

  const UserThemeContextMemo = useMemo(() => [theme, setUserTheme], [theme])

  // Set defaultTheme in local storage
  useEffect(() => {
    if (!checkStorage('defaultTheme')) {
      setStorage('defaultTheme', defaultTheme)
    }
  }, [defaultTheme])

  // Get defaultTheme from local storage, if it exists
  useEffect(() => {
    // If userTheme is present
    if (checkStorage('userTheme')) {
      // Set userTheme state
      setUserTheme(getStorage('userTheme'))
      // Else if defaultTheme is present
    } else if (checkStorage('defaultTheme')) {
      // Set defaultTheme state
      setDefaultTheme(getStorage('defaultTheme'))
    }
  }, [])

  // Listen for system defaultTheme changes
  useEffect(() => {
    const mqListener = (e) =>
      e.matches ? setDefaultTheme('dark') : setDefaultTheme('light')
    darkMqList.addEventListener('change', mqListener)
    return () => {
      darkMqList.removeEventListener('change', mqListener)
    }
  }, [darkMqList])

  const getSkeletonTheme = (skeletonTheme) => {
    const options = {
      light: {
        baseColor: gray100,
        highlightColor: gray150,
        borderRadius: 'inherit',
      },
      dark: {
        baseColor: gray1100,
        highlightColor: gray1150,
        borderRadius: 'inherit',
      },
    }
    return options[skeletonTheme]
  }

  const html = document.documentElement
  useEffect(() => {
    html.setAttribute('class', theme)
  }, [html, theme, userTheme, defaultTheme])

  return (
    <SkeletonTheme {...getSkeletonTheme(theme)}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Jason Cardarelli</title>
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={`favicon/${theme}/favicon-32x32.png`}
        />
      </Helmet>
      <div className='App'>
        <CurrentBpContext.Provider value={currentBp}>
          <UserThemeContext.Provider value={UserThemeContextMemo}>
            <Header />
            <main>
              <Outlet context={theme} />
            </main>
            <Footer />
          </UserThemeContext.Provider>
        </CurrentBpContext.Provider>
      </div>
    </SkeletonTheme>
  )
}

export default App
